import React, { useEffect, useState } from 'react';
import { useInjection } from '../../../di/useInjection';
import { IncomeInfo } from '../../../network/ClientActions';
import Box from '@mui/material/Box';
import { centsToDollars } from '../../../helpers';


export const IncomeInfoView: React.FC<Props> = ({ userId }) => {
  const { clientActions } = useInjection();
  const [incomeInfoResult, setIncomeInfoResult] = useState<[IncomeInfo?, string?] | null>(null);

  useEffect(() => {
    if (userId !== undefined) {
      clientActions.fetchIncomeInfo(
        parseInt(userId)
      ).then((result) => {
        setIncomeInfoResult(result);
      });
    }
  }, []);

  const isLoading = incomeInfoResult === null;
  const [incomeInfo, error] = !isLoading ? incomeInfoResult : [null, null];

  return (<>
    <Box sx={{
      mt: 4.3,
      background: "#2a41b0",
      color: "#fff",
      padding: "0 20px",
      borderRadius: "10px 10px 0 0",
    }}>
      <h4>
        Income Info
      </h4>
    </Box>

    <Box component="section" sx={{
      p: 2,
      background: "white",
      borderRadius: "1px",
      fontSize: "14px",
      width: "250px"
    }}>
      {isLoading && <span>Loading...</span>}

      {error !== null && <span>{error}</span>}

      {incomeInfo !== null && <div>
        <Box sx={{ color: "blue" }}>
          <h6>Balance transfer</h6>
        </Box>
        <p>
          mo account: {centsToDollars(incomeInfo?.balanceTransfer?.monthlyAccountsIncome ?? 0)}<br />
          mo argyle: {centsToDollars(incomeInfo?.balanceTransfer?.monthlyArgyleIncome ?? 0)} <br />
          mo full: {centsToDollars(incomeInfo?.balanceTransfer?.monthlyFullIncome ?? 0)}
        </p>

        <Box sx={{ color: "blue" }}>
          <h6>Credit builder</h6>
        </Box>
        <p>
          mo account: {centsToDollars(incomeInfo?.creditBuilder?.monthlyAccountsIncome ?? 0)}<br />
          mo argyle: {centsToDollars(incomeInfo?.creditBuilder?.monthlyArgyleIncome ?? 0)} <br />
          mo full: {centsToDollars(incomeInfo?.creditBuilder?.monthlyFullIncome ?? 0)}
        </p>

        <Box sx={{ color: "blue" }}>
          <h6>BillPay</h6>
        </Box>
        <p>
          mo account: {centsToDollars(incomeInfo?.billPay?.monthlyAccountsIncome ?? 0)}<br />
          mo argyle: {centsToDollars(incomeInfo?.billPay?.monthlyArgyleIncome ?? 0)} <br />
          mo full: {centsToDollars(incomeInfo?.billPay?.monthlyFullIncome ?? 0)}
        </p>
      </div>}
    </Box>
  </>
  );
}

type Props = {
  userId?: string | undefined
}