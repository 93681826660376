import { AchNumberVerificationCollection } from "../../collections/ach-number-verification-collection/AchNumberVerificationCollection";
import { PendingManualReviewRequestCollection } from "../../collections/pending_manual_review_requests/PendingManualReviewReqestsCollection";
import { SeeminglyDiedTokenCollection } from "../../collections/seemingly-died-tokens-collection/SeeminglyDiedTokensCollection";
import { WaitingUsersCollection } from "../../collections/waiting-users-collection/WaitingUsersCollection";

export const UnderwritingDashboard = () => {
  return (
    <section className='dashboard'>
      <h1>Underwriting</h1>

      <WaitingUsersCollection />

      <PendingManualReviewRequestCollection
        disableUrlFilterListener
        initialFilters={{
          // has_uw_decision: { eq: false },
          // decision: { eq: "PENDING" },
        }}
      />

      <SeeminglyDiedTokenCollection
        initialFilters={{
          "status": "Active",
        }}
      />

      <AchNumberVerificationCollection
        initialFilters={{}} />

    </section>
  );
}
