import { AccountsOverview } from '../types/accountsOverview';
import { BillingCycle } from '../types/billingCycles';
import { UnderwritingDecisionCheck } from '../types/underwritingDecisionsChecks';
import { AdminRequest, PlacidResponse, PostError } from './AdminRequest';


export class DebtActions {
  constructor(private adminRequest: AdminRequest) { };

  async updatePBTransaction(payload: { [index: string]: any }): Promise<PostError> {
    try {
      return this._checkPlacidError(await this.adminRequest.post('/admin/pb-txn/adjust', JSON.stringify(payload)));
    } catch (e) {
      return (e as Error).message;
    }
  }

  async createPBTransaction(payload: { [index: string]: any }): Promise<PostError> {
    try {
      return this._checkPlacidError(await this.adminRequest.post('/admin/pb-txn/add', JSON.stringify(payload)));
    } catch (e) {
      return (e as Error).message;
    }
  }

  async updateBillingCycleDD(payload: { userId: number, billingCycleId: number, dueDate: string }): Promise<PostError> {
    try {
      return this._checkPlacidError(await this.adminRequest.post('/admin/billing-cycles/update-due-date', JSON.stringify(payload)));
    } catch (e) {
      return (e as Error).message;
    }
  }

  async cancelPBTransaction(payload: { userId: number, transactionId: number, comment: string, }): Promise<PostError> {
    try {
      return this._checkPlacidError(await this.adminRequest.post('/admin/pb-txn/cancel', JSON.stringify(payload)));
    } catch (e) {
      return (e as Error).message;
    }
  }

  async fetchAccountsOverview(userId: string): Promise<AccountsOverview> {
    return this.adminRequest.getAsUser<AccountsOverview>({
      userId: userId,
      path: 'v6/get-accounts-overview',
    });
  }

  async oneTimePayment(
    userId: string,
    payload: { amount: number, accountId: string, itemId: string },
  ): Promise<PostError> {
    return this.adminRequest.postAsUser({
      userId,
      path: "payment-method/one-time-payment/send",
      payload,
    })
  }

  async fetchBillingCycle(userId: string): Promise<BillingCycle[]> {
    return this.adminRequest.getView(
      `/admin/generic-views/collection_single_bill_cycles?user_id=eq.${userId}&order=active_from.desc`
    );
  }

  _checkPlacidError(placidResponse: PlacidResponse): PostError {
    if (placidResponse.success) {
      return null
    } else {
      return placidResponse.error.message ?? "Something went wrong";
    }
  }
}