import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  DateInput,
  SelectInput,
  TextInput,
  useNotify,
  useRefresh,
} from "react-admin"
import { Resource } from '../../common/Resource';
import { ColumnModel } from '../../model/ColumnModel';
import { RowClickEvent } from '../../common/Table';
import { useInjection } from '../../di/useInjection';
import { Box } from '@mui/material';
import { UpdateManualReviewPayload } from '../../network/ManualReviewActions';

export const resource = "admin/generic-views/pending_manual_review_requests";

const tableColumns = [
  { field: "id", width: "120px", title: "user id", show: true },
  { field: "accounts_in_review", width: "120px", title: "accounts in review", show: true },
  { field: "created_at", width: "180px", title: "requested at", show: true, format: "yyyy-MM-dd hh:mm" },
  { field: "onboarding_flow", width: "180px", title: "onboarding flow", show: true },

  // { field: "decision", width: "120px", title: "decision", show: true },
  // { field: "has_uw_decision", width: "120px", title: "has uw decision", show: true, },

  // { field: "actions", width: "120px", title: "actions", show: true },
]

const filters = [
  <TextInput label="id" source="id.eq" alwaysOn />,

  <DateInput label="requested at gt" source="created_at.gt" alwaysOn />,
  <DateInput label="requested at lt" source="created_at.lt" alwaysOn />,

  // <SelectInput source="decision" choices={[
  //   { id: 'PENDING', name: 'PENDING', },
  //   { id: 'REJECT', name: 'REJECT' },
  //   { id: 'APPROVE', name: 'APPROVE' },
  // ]} />,

  // <SelectInput source="has_uw_decision" choices={[
  //   { id: 'true', name: 'yes' },
  //   { id: 'false', name: 'no' },
  // ]} />,
];

export const PendingManualReviewRequestCollection: React.FC<Props> = (props) => {
  const {
    initialFilters,
    onRowClick,
    disableUrlFilterListener
  } = props;
  const notify = useNotify();
  const refresh = useRefresh();
  const { manualReviewActions } = useInjection();

  const handleRowClick = ({
    dataItem,
    nativeEvent
  }: RowClickEvent,
  ) => {
    if (onRowClick === undefined) return;

    nativeEvent.stopPropagation();
    nativeEvent.preventDefault();

    const { id } = dataItem;

    if (id === undefined) {
      throw Error('no user_id in row click event');
    }

    onRowClick(id);
  }

  const columnModel = useMemo(() => {
    return new ColumnModel(resource, tableColumns);
  }, [])

  const handleUpdateManualReview = async (payload: UpdateManualReviewPayload) => {
    const error = await manualReviewActions.updateManualReview(payload);

    if (error === null) {
      notify("done");
      refresh();
    } else {
      notify(error);
    }
  }

  return (
    <Box sx={{ position: "relative", paddingTop: "60px" }}>

      <Box sx={{
        position: "absolute",
        background: "#2a41b0",
        color: "#fff",
        padding: "0 20px",
        borderRadius: "10px 10px 0 0",
        top: "30px",
      }}>
        <h4>
          {`Manual review requests`}
        </h4>
      </Box>

      <Resource
        initialFilters={initialFilters}
        resource={resource}
        filters={filters}
        onRowClick={handleRowClick}
        columnModel={columnModel}
        disableUrlFilterListener={disableUrlFilterListener}
        // rowActionBuilder={rowActionBuilder}
        idLink={(data: { [index: string]: any }) => {
          const id = data["id"];
          return `/dash/underwriting-user/${id}/`;
        }}
      />
    </Box>
  )
}

type Props = {
  initialFilters?: { [index: string]: any }
  onRowClick?: (underwritingId: string) => void
  disableUrlFilterListener: boolean
}